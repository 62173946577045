import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    // Redirect to the new URL
    window.location.href = "https://filixefa.in";
  }, []);

  return (
    <div>
      <h1>Redirecting to Filixefa.in...</h1>
    </div>
  );
}